<template>
  <div>Report</div>
</template>

<script>
export default {
  name: 'Report'
}
</script>

<style></style>
